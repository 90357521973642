/* General Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}

/* Header */
.header {
    background-color: var(--mui-palette-primary-main);
    color: white;
    padding: var(--mui-spacing-2);
    display: flex;
    align-items: center;
}

.logo {
    height: 100px;
    width: 100px;
    margin-right: 120px;
}

/* Image Container */
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    background-color: #f8f8f8;
}

.styled-image {
    height: 100%;
    width: auto;
    transition: transform 0.3s ease-in-out;
}

.styled-image:hover {
    transform: scale(1.1);
}

/* Bio Section */
.bio-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--mui-spacing-5);
    text-align: center;
}

.bio-image {
    width: 150px; /* Updated size for smaller view */
    height: 150px; /* Updated size for smaller view */
    border-radius: 50%;
    margin: var(--mui-spacing-2);
}

.bio-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Grid Container */
.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    white-space: nowrap;
    text-align: center;
}

.carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    text-align: center;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

/* Circular Card */
.circular-card {
    background-color: #d65a58;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px; /* Adjust height */
    width: 150px; /* Adjust width */
    border-radius: 50%;
    margin: 10px;
}

/* Typography */
.Typography {
    font-family: 'BirdsOfParadise';
}

/* Card Styles */
.MuiCard-root {
    border: none; /* Remove border */
    box-shadow: none; /* Remove box shadow */
}


/* Card Layout */
.image-text-card {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.card-text {
    padding: 20px;
}

.card-image img {
    width: 100%;
    height: auto;
}

.grid-container-reverse {
    display: flex;
    flex-direction: row-reverse; /* Reverses the order of the items */
    align-items: center;
}

@media (max-width: 768px) {
    .grid-container-reverse {
        flex-direction: column; /* Reverts to column layout on smaller screens */
    }
}

/* Rave Section */
.rave-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.rave-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width on small screens */
    max-width: 300px; /* Adjust to fit 3 items per row on larger screens */
    box-sizing: border-box;
}

.rave-item img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 10px;
}

.test {
    font-size: 100px;
    width: auto;
    height: auto;
}

.rave-item p {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}

.rave-item span {
    margin-top: 15px;
    font-weight: bold;
}

#rave {
    text-align: center;
    margin: 50px 0;
}

#rave h1 {
    font-size: 2rem; /* Adjusted for better mobile display */
    font-family: sans-serif;
    margin-bottom: 20px;
}

/* Cake Gallery Extravaganza */
.cake-gallery-extravaganza {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: center;
    margin-top: 20px;
}

.gallery-container {
    /* display: flex; */
    overflow-x: hidden;
    white-space: nowrap;
    padding: 10px 0;
}

.gallery-container img {
    width: 30rem;
    height: auto;
    margin: 10px;
    border-radius: 10%;
}

.cake-images {
    height: 350px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin: 10px;
    border-radius: 10px;
}
.cake-images-divine {
    height: 350px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin: 10px;
    border-radius: 10px;
}

.grid-divine {
    max-width: 360px;
    margin-left: 200px;
}


/* Moving Text */
.moving-text {
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: #fff;
}

.moving-text marquee {
    display: block;
    white-space: nowrap;
    font-size: 1.5rem;
    padding: 10px;
    color: #333;
}

/* Media Queries */
@media (max-width: 768px) {
    .header, .bio-section, .grid-container, .rave-container, .cake-gallery-extravaganza {
        padding: 10px;
    }

    .logo {
        height: 80px;
        width: 80px;
    }

    .bio-image {
        width: 120px; /* Updated size for smaller view */
        height: 120px; /* Updated size for smaller view */
        border-radius: 50%;
        margin: var(--mui-spacing-2);
    }

    .rave-item {
        width: 100%;
        max-width: none; /* Full width for smaller screens */
    }

    .gallery-container img {
        width: 150px; /* Adjusted for smaller screens */
    }

    #rave h1 {
        font-size: 1.5rem; /* Adjusted for smaller screens */
    }

    .circular-card {
        height: 100px; /* Smaller circular card size for mobile */
        width: 100px; /* Smaller circular card size for mobile */
    }

    .image-text-card {
        flex-direction: column; /* Column layout for smaller screens */
    }

    .masterpiece-card{
        flex-direction: column-reverse; /* Reversed layout for Masterpiece Cakes on small screens */
        height: auto; /* Further reduce size */
        width: auto;
    }
    .cake-images-divine {
        height: 350px;
        margin-bottom: 20px;
        margin-top: 20px;
        margin-left: 50px;
        border-radius: 10px;
        margin-right: 20px;
    }
    .grid-divine {
        max-width: 360px;
        margin: -5px;
    }
    
    
}

@media (max-width: 550px) {
    .gallery-container img {
        width: 120px; /* Further adjust for very small screens */
    }

    .circular-card {
        height: 80px; /* Further reduce size */
        width: 80px; /* Further reduce size */
    }
    .grid-divine {
        max-width: 360px;
    }
}

.cake-chronicles {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px; /* Increased padding for better spacing */
  }
  
  .cake-chronicles h2 {
    margin-bottom: 40px; /* Increased bottom margin for better separation */
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center; /* Center the title */
    color: #333; /* Add color for better contrast */
  }
  
  .chronicle-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for emphasis */
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added shadow transition for hover effect */
    cursor: pointer;
    display: flex;
    flex-direction: column; /* Ensure content is properly aligned */
    height: 100%; /* Ensures all cards are of equal height */
  }
  
  .chronicle-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .chronicle-image {
    width: 100%;
    height: 200px; /* Fixed height to maintain consistency */
    object-fit: cover;
  }
  
  .chronicle-card .MuiCardContent-root {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1; /* Ensures content takes up remaining space */
  }
  
  .chronicle-card .MuiTypography-body2 {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 10px;
  }
  
  .chronicle-card .MuiTypography-h5 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333; /* Darker text color for better readability */
  }
  
  .chronicle-card .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 1.6; /* Slightly increased line-height for readability */
    color: #666; /* Softer black for less strain on the eyes */
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .chronicle-card .MuiTypography-h5 {
      font-size: 1.2rem;
    }
  
    .chronicle-card .MuiTypography-body1 {
      font-size: 0.9rem;
    }
  }

 /* Define the animations */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    opacity: 0; /* Start invisible */
    transform: translateY(50px); /* Start with some offset */
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  .fade-in-up.active {
    opacity: 1;
    transform: translateY(0);
}