.logo {
    height: 5rem;
    width: 5rem;
  }
  .logo {
    height: 56px;
    width: 56px;
    border-radius: 50%;
  }
  
  .MuiAppBar-root {
    box-shadow: none;
    backdrop-filter: blur(10px);
  }
  
  .MuiButton-root {
    font-weight: 600;
  }
  
  .navbar {
    z-index: 1000; /* Adjust the value as needed */
  }