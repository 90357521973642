/* body {
    font-family: 'Arial, sans-serif';
    background-color: #f2f2f2;
    color: #333;
    overflow-x: hidden;
} */

.confetti, .fireworks, .balloons {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 100;
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 255, 221, 0.3);
    z-index: 10;
}

.cake {
    position: relative;
    width: 300px;
    height: auto;
}

.cake-img {
    width: 100%;
    border-radius: 10px;
}

.candles {
    position: absolute;
    top: -10%;
    left: 40%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-around;
    width: 80%;
}

.candle {
    position: relative;
    width: 50px;  /* Increased width */
    height: 300px; /* Increased height */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.candle img {
    width: 300%;
    height: auto;
}

.flame {
    position: absolute;
    top: 25px; /* Adjust this value to position the flame correctly */
    left: 52px;
    width: 10px;
    height: 20px;
    background: radial-gradient(circle, yellow, orange, red);
    border-radius: 50%;
    animation: flicker 0.2s infinite alternate;
}

@keyframes flicker {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

.smoke {
    position: absolute;
    top: 5px;
    left: 57px;
    width: 5px;
    height: 50px;
    background: linear-gradient(to top, grey, transparent);
    opacity: 0;
    animation: rise 2s forwards;
}

@keyframes rise {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

.birthday-letter, .gallery, .gift {
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gallery img {
    width: 100px;
    margin: 10px;
}

.balloon {
    position: absolute;
    bottom: -100px;
    width: 50px;
    height: 70px;
    background: radial-gradient(circle, red, darkred);
    border-radius: 50%;
    animation: float 10s linear infinite;
    cursor: pointer;
}

.balloon::before {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 50%;
    width: 2px;
    height: 50px;
    background: rgb(0, 0, 0);
}

.balloon.popped {
    background: transparent !important;
    box-shadow: none !important;
    animation: pop 0.5s forwards; /* Add pop animation */
}

@keyframes pop {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}
canvas {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

@keyframes float {
    0% {
        bottom: -100px;
        transform: translateX(0);
    }
    100% {
        bottom: 110%;
        transform: translateX(20px);
    }
}
