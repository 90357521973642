.filters {
    margin-bottom: 20px;
  }
  
  .filters label {
    display: block;
  }
  
  .card {
    position: relative;
  }
  
  
  .card .new-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
    color: white;
    padding: 5px;
    border-radius: 3px;
  }
  
  .card .offer {
    background: blue;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  .card .offer p {
    margin: 0;
  }

  @media (max-width: 768px) {
    .drawer {
      top: 80px; /* Adjust based on the reduced navbar height on smaller screens */
      width: 100%;
    }
  }
  